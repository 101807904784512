var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-10 col-md-11 col-12"},[_c('h1',[_vm._v(_vm._s(_vm.companyName))]),_c('p',[_vm._v(_vm._s(_vm.companyCvr))])])]),_c('NavigationSlide',{attrs:{"nav":[
		{
			name: 'Company',
			params: '{ companyId: ' + _vm.id + '}',
			text: _vm.$t('companyBase.info'),
			icon: 'building',
			hidden: !_vm.$can('read', 'Company', 'CompanyInfo'),
		},
		{
			name: 'CompanyAdvisor',
			params: '{ companyId: ' + _vm.id + '}',
			text: _vm.$t('companyBase.advisors'),
			icon: 'hands-helping',
			hidden: !_vm.$can('read', 'Company', 'CompanyAdvisors'),
		},
		{
			name: 'CompanyGroup',
			params: '{ companyId: ' + _vm.id + '}',
			text: 'Grupper',
			icon: 'hands-helping',
			hidden: !_vm.$can('read', 'Company', 'Group'),
		},
		{
			name: 'CompanyPensionList',
			params: '{ companyId: ' + _vm.id + '}',
			text: _vm.$t('companyBase.pension'),
			icon: 'list-alt',
			hidden: !_vm.$can('read', 'Company', 'Pensions'),
		},
		{
			name: 'CompanyInsuranceList',
			params: '{ companyId: ' + _vm.id + '}',
			text: _vm.$t('companyBase.insurance'),
			icon: 'house-damage',
			hidden: !_vm.$can('read', 'Company', 'Insurances'),
		},
		{
			name: 'CompanyEmployeeList',
			params: '{ companyId: ' + _vm.id + '}',
			text: _vm.$t('companyBase.employeeList'),
			hidden: !_vm.$can('read', 'Company', 'Employees'),
			icon: 'users',
		},
		{
			name: 'CompanyAdminList',
			params: '{ companyId: ' + _vm.id + '}',
			text: _vm.$t('companyBase.companyAdminList'),
			hidden: !_vm.$can('read', 'Company', 'CompanyAdmins'),
			icon: 'users',
		},
		{
			name: 'CompanyKycList',
			params: '{ companyId: ' + _vm.id + '}',
			text: 'Ejerforhold',
			hidden: !_vm.$can('read', 'Company', 'CompanyAdmins'),
			icon: 'passport'
		},
		{
			name: 'CompanyLogs',
			params: '{ companyId: ' + _vm.id + '}',
			text: _vm.$t('companyBase.logs'),
			icon: 'server',
			hidden: !_vm.$can('read', 'Company', 'Logs'),
		},
		{
			name: 'CompanySettings',
			params: '{ companyId: ' + _vm.id + '}',
			text: _vm.$t('companyBase.settings'),
			icon: 'cog',
			hidden: !_vm.$can('update', 'Company', 'Settings'),
		} ]}}),(_vm.isReady)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-view')],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }