<template>
	<section class="container-fluid">
		<div class="row justify-content-center">
			<div class="col-lg-10 col-md-11 col-12">
				<h1>{{ companyName }}</h1>
				<p>{{ companyCvr }}</p>
			</div>
		</div>

		<NavigationSlide :nav="[
			{
				name: 'Company',
				params: '{ companyId: ' + id + '}',
				text: $t('companyBase.info'),
				icon: 'building',
				hidden: !$can('read', 'Company', 'CompanyInfo'),
			},
			{
				name: 'CompanyAdvisor',
				params: '{ companyId: ' + id + '}',
				text: $t('companyBase.advisors'),
				icon: 'hands-helping',
				hidden: !$can('read', 'Company', 'CompanyAdvisors'),
			},
			{
				name: 'CompanyGroup',
				params: '{ companyId: ' + id + '}',
				text: 'Grupper',
				icon: 'hands-helping',
				hidden: !$can('read', 'Company', 'Group'),
			},
			{
				name: 'CompanyPensionList',
				params: '{ companyId: ' + id + '}',
				text: $t('companyBase.pension'),
				icon: 'list-alt',
				hidden: !$can('read', 'Company', 'Pensions'),
			},
			{
				name: 'CompanyInsuranceList',
				params: '{ companyId: ' + id + '}',
				text: $t('companyBase.insurance'),
				icon: 'house-damage',
				hidden: !$can('read', 'Company', 'Insurances'),
			},
			{
				name: 'CompanyEmployeeList',
				params: '{ companyId: ' + id + '}',
				text: $t('companyBase.employeeList'),
				hidden: !$can('read', 'Company', 'Employees'),
				icon: 'users',
			},
			{
				name: 'CompanyAdminList',
				params: '{ companyId: ' + id + '}',
				text: $t('companyBase.companyAdminList'),
				hidden: !$can('read', 'Company', 'CompanyAdmins'),
				icon: 'users',
			},
			{
				name: 'CompanyKycList',
				params: '{ companyId: ' + id + '}',
				text: 'Ejerforhold',
				hidden: !$can('read', 'Company', 'CompanyAdmins'),
				icon: 'passport'
			},
			{
				name: 'CompanyLogs',
				params: '{ companyId: ' + id + '}',
				text: $t('companyBase.logs'),
				icon: 'server',
				hidden: !$can('read', 'Company', 'Logs'),
			},
			{
				name: 'CompanySettings',
				params: '{ companyId: ' + id + '}',
				text: $t('companyBase.settings'),
				icon: 'cog',
				hidden: !$can('update', 'Company', 'Settings'),
			},
		]" />

		<div class="row" v-if="isReady">
			<div class="col">
				<router-view />
			</div>
		</div>
	</section>
</template>

<script>
import NavigationSlide from '@/modules/global/components/NavigationSlide.vue'
export default {
	name: 'BaseCompany',
	components: { NavigationSlide },
	async created() {
		await this.$store.dispatch('companyVuex/reloadCompany', this.id)
		this.$emit('updateLoader')
	},
	computed: {
		companyName() {
			return this.company?.companyInfo?.name ?? ' '
		},
		companyCvr() {
			return this.company?.companyInfo?.cvr ?? ' '
		},
		id() {
			return this.$route.params.companyId
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
		isReady() {
			return this.$store.getters['companyVuex/isReady']
		},
	},
	destroyed() {
		this.$store.dispatch('companyVuex/clearState')
	},
}
</script>

<style></style>
